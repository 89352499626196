import './styles/App.scss';

import React from 'react';

import {Header, MainSection} from './components';

function App() {
  return (
    <div className='app-container'>
      <div id='top' className='app-wrapper'>
        <Header />
        <MainSection />
      </div>
    </div>
  );
}

export default App;
