import './Footer.scss';

import React from 'react';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-content'>
        <div className='footer-links'>
          <a href='https://jobs.ashbyhq.com/altera.al' target='_blank' rel='noopener noreferrer'>
            Careers
          </a>
          <a href='mailto:contact@altera.al' target='_blank' rel='noopener noreferrer'>
            Enterprise
          </a>
          <a href='https://substack.com/@digitalhumanity' target='_blank' rel='noopener noreferrer'>
            Blog
          </a>
        </div>
        <div className='footer-links'>
          <a href='https://www.linkedin.com/company/stealth-al/' target='_blank' rel='noopener noreferrer'>
            LinkedIn
          </a>
          <a href='https://x.com/Altera_AL' target='_blank' rel='noopener noreferrer'>
            X
          </a>
          <a href='https://discord.gg/alteraal' target='_blank' rel='noopener noreferrer'>
            Discord
          </a>
        </div>
      </div>
      <hr style={{border: '1px solid #dcdcdc91', margin: '1rem 0'}} />
      <p>©2024 Altera.AL</p>
    </footer>
  );
};

export default Footer;
