import './Blogs.scss';

import {motion} from 'framer-motion';
import React from 'react';

import blogs from './data/blogs.json';

const Blogs = () => {
  return (
    <div className='blogs'>
      <motion.h3
        initial={{opacity: 0, y: -20}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.2, delay: 0.6}}
        className='blogs_header'
      >
        Latest Blog Posts
      </motion.h3>
      <motion.div
        className='blogs_container'
        initial='hidden'
        animate='visible'
        variants={{
          visible: {
            transition: {
              staggerChildren: 0.1,
              delayChildren: 0.8, // starts after header animation
            },
          },
        }}
      >
        {blogs.map((blog, i) => (
          <motion.div
            key={i}
            className='blogs_card'
            onClick={() => window.open(blog.link, '_blank')}
            whileHover={{scale: 1.05}}
            variants={{
              hidden: {opacity: 0, x: 20},
              visible: {opacity: 1, x: 0},
            }}
          >
            <img src={blog.img} alt={blog.title} />
            <h4>{blog.title}</h4>
            <p className='blogs_date'>{blog.date}</p>
            {!blog.desc_link ? (
              <p>{blog.description}</p>
            ) : (
              <p>
                <a
                  className='blogs_desc_link'
                  href={blog.desc_link}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={(e) => e.stopPropagation()}
                >
                  {blog.description}
                </a>
              </p>
            )}
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default Blogs;
