import './MainSection.scss';

import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AnimatePresence, motion} from 'framer-motion';
import React, {useState} from 'react';

import Blogs from '../Blogs/Blogs';
import Footer from '../Footer/Footer';
import moreInfo from './data/moreInfo.json';
import team from './data/team.json';

const MainSection = () => {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (index) => {
    setOpenSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div id='about' className='main'>
      <div className='main_container'>
        <div className='main_container_c1'>
          {/* HEADER */}
          <h1 className='main_top'>
            <motion.span initial={{opacity: 0, y: 20}} animate={{opacity: 1, y: 0}} transition={{duration: 0.6}}>
              <span className='btn1'>
                <img src='/img/logo_text.png' alt='Altera Logo' className='main_logo' />
              </span>{' '}
              is a multi-agent research company building digital humans: machines with fundamental human qualities.
            </motion.span>
            <br /> <br />
            <motion.span
              initial={{opacity: 0, y: 20}}
              animate={{opacity: 1, y: 0}}
              transition={{duration: 0.6, delay: 0.3}}
            >
              Try{' '}
              <motion.a
                href='https://playlabs.altera.al'
                target='_blank'
                className='btn2'
                whileHover={{
                  scale: 1.1,
                  transition: {
                    type: 'spring',
                    stiffness: 200,
                    damping: 15,
                    duration: 1.02,
                  },
                }}
                initial={{scale: 1}}
              >
                Playlabs
              </motion.a>{' '}
              - the first ever autonomous agent that can play Minecraft with you.
            </motion.span>
          </h1>
          {/* SECOND HEADER */}
          <motion.div
            initial={{opacity: 0, y: -20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.2, delay: 0.6}}
            className='main_middle'
          >
            <p>
              We shut down our lab at MIT on December 1st, 2023 to start an applied research company building frontier
              AI agents.
              <br /> <br />
              We are led by{' '}
              <a
                href='https://scholar.google.com/citations?user=hrI8aH8AAAAJ&hl=en'
                target='_blank'
                rel='noopener noreferrer'
              >
                Dr. Robert Yang
              </a>{' '}
              and are a small team of computational neuroscientists, CS and physics olympiads, and engineers from MIT
              EECS, Stanford NLP Group, Google X, Citadel, Supercell, and more.
              <br /> <br />
              Over the past year, we have raised $11M from Andreessen Horowitz, Eric Schmidt&apos;s First Spark
              Ventures, Patron VC, and more.
              <br /> <br />
              We believe digital beings and digital relationships will enhance, not replace, human-to-human
              interactions. As digital humans come to vastly outnumber us, they should become a source of empathy, fun,
              friendship, and productivity.
              <br /> <br />
              Our mission: <b>Create digital human beings that live, care, and grow with us.</b>
            </p>
          </motion.div>

          {/* PARAGRAPHS */}
          <div className='main_bottom'>
            <div className='main_bottom_firstSection'>
              <div className='dropdown-container'>
                {moreInfo.map((info, i) => (
                  <div key={i} className='dropdown-section'>
                    <motion.div
                      className='dropdown-header'
                      onClick={() => toggleSection(i)}
                      style={{cursor: 'pointer'}}
                    >
                      <h1>{info.title}</h1>
                      <motion.div
                        animate={{rotate: openSections[i] ? 180 : 0}}
                        transition={{duration: 0.3}}
                        className='dropdown-arrow'
                      >
                        <FontAwesomeIcon icon={faChevronDown} />
                      </motion.div>
                    </motion.div>
                    {(i !== moreInfo.length - 1 || openSections[i]) && (
                      <div style={{height: '1px', backgroundColor: '#dcdcdc91', margin: '1rem 0'}} />
                    )}

                    <AnimatePresence>
                      {openSections[i] && (
                        <motion.div
                          initial={{height: 0, opacity: 0}}
                          animate={{height: 'auto', opacity: 1}}
                          exit={{height: 0, opacity: 0}}
                          transition={{duration: 0.3}}
                          style={{overflow: 'hidden'}}
                        >
                          {info.description.map((desc, j) => (
                            <p className='main_bottom_firstSection_paragraph' key={j}>
                              {desc}
                            </p>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ))}
              </div>
            </div>
            <div className='main_bottom_last'>
              <h1>Team</h1>
              <div className='main_bottom_last_grid'>
                {team.map((person, i) => (
                  <motion.div key={i} className='main_bottom_last_grid_unit' whileHover={{scale: 1.05}}>
                    <div className='main_bottom_last_grid_unit_name'>
                      <h2>{person.name}</h2>
                      <p className={`pastel${Math.floor(Math.random() * 6) + 1}`}>{person.job}</p>
                    </div>
                    <p className='main_bottom_last_grid_unit_info'>{person.description}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <Blogs />
      </div>
    </div>
  );
};

export default MainSection;
