import './Header.scss';

import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {motion} from 'framer-motion';
import React from 'react';

const Header = () => {
  return (
    <div className='header'>
      <motion.div
        initial={{opacity: 0, y: -20}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.2, delay: 0.6}}
        className='header_content'
      >
        <a href='https://github.com/altera-al/project-sid' className='header_logo'>
          <img src='./img/logo_dark.png' alt='Altera Logo' />
          <div>
            <FontAwesomeIcon icon={faChevronRight} />
            <p>Our Tech Report on Multi-Agent Civs</p>
          </div>
        </a>
        <motion.div whileHover={{y: -2}}>
          <button onClick={() => window.open('https://jobs.ashbyhq.com/altera.al', '_blank')}>
            <p>We&apos;re hiring</p>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Header;
